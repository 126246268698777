<template>
  <div class="d-flex" @click="handleFriendItem(item)">
    <div class="avatar mr-3">
      <!-- 头像 -->
      <!-- <img :src="item.avatar || defaultAvatar" class="avatar-50" /> -->
      <el-avatar :size="50" shape="square" :src="item.avatar" @error="true">
        <img src="@/assets/images/public/user.png"/>
      </el-avatar>
      <!-- 是否在线小圆点 -->
      <span class="avatar-status">
        <i
          class="ri-checkbox-blank-circle-fill"
          :class="item.is_online == 1 ? 'text-success' : 'text-warning'"
          v-if="item.is_online == 1"
        />
      </span>
    </div>
    <!-- 名字及消息 -->
    <div class="chat-sidebar-name d-flex flex-column justify-content-around">
      <h6 class="mb-0 text-overflow-single widthStyle">
        {{ item.nick_name }}
      </h6>
      <span
        class="text-overflow-single widthStyle"
        :title="item.last_msg"
        v-if="!isShow(item.last_msg)"
        >
        <span v-if="item.msg_type==5">[sticker]</span>
        <span v-else>{{ item.last_msg }}</span>
        </span
      >
      <span class="text-overflow-single widthStyle" v-else>
        Share content with you
      </span>
    </div>
    <!-- 消息条数及消息时间 -->
    <div
      class="chat-meta float-right text-center mt-2 mr-1"
      v-if="item.subscript != undefined && item.subscript != 0"
    >
      <div class="chat-msg-counter bg-primary text-white">
        {{ item.subscript }}
      </div>
      <!-- <span class="text-nowrap">05 min</span> -->
    </div>
  </div>
</template>
<script>
import defaultAvatar from "@/assets/images/public/user.png";

export default {
  name: "ChatItem",
  props: {
    item: { type: Object },
  },
  data() {
    return {
      defaultAvatar,
    };
  },
  methods: {
    // 点击好友列表获取单条好友信息
    handleFriendItem(item) {
      item.type = "friend";
      this.$emit("clickItemInfo", item);
    },
    isShow(item) {
      if (typeof item == "string") {
        try {
          var obj = JSON.parse(item);
          if (typeof obj == "object" && obj && obj.share_type) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@media (min-width: 600px){
  .widthStyle {
    width: 110px;
  }
}
@media (max-width: 600px){
  .avatar{
    width:40px !important;
  }
  .widthStyle{
    width: 50px;
  }
}
</style>
