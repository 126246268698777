<template>
  <section class="ShareMeetingCard">
    <!-- 会议     => 分享 -->
    <div class="chat-detail">
      <div
        class="chat-message-share cursor-pointer"
        @click="handleClickJudgment"
      >
        <div class="d-flex">
          <div class="meetingIcon">
            <i class="ri-slideshow-2-line"></i>
          </div>
          <div>
            <div class="mb-1 title" :title="params.title">
              {{ params.title }}
            </div>
            <div class="time">
              {{ params.time }}
            </div>
          </div>
        </div>
        <hr class="mb-2" />
        <p class="mb-0 cardDesc">{{ $t("Chat.25f@share_a_meeting_to_y") }}</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "",
  props: {
    params: {
      type: Object,
    },
  },
  components: {},
  data () {
    return {};
  },
  watch: {},
  methods: {
    handleClickJudgment () {
      const data = {
        share_type: 2,
        share_id: this.params.id,
      };
      this.$http.sharedContentExist(data).then((res) => {
        if (res.status == 200) {
          if (res.data) {
            this.$router.push(`/meeting-details?id=${this.params.id}&type=4`);
          } else {
            this.$message.warning(this.$t('Chat.4ae@this_meeting_has_bee'));
          }
        }
      });
    },
  },
  created () { },
};
</script>
<style src='' lang='scss' scoped>
  @media (min-width: 600px){
    .title{
      width: 210px;
    }
    .time {
      font-size: 12px;
    }
  }
  @media (max-width: 600px){
    .title{
      width: 70px;
    }
    .time {
      font-size: 8px;
    }
  }
.ShareMeetingCard {
  .meetingIcon {
    width: 60px;
    height: 60px;
    background: #f5fbff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    i {
      font-size: 25px;
      color: #50b5ff;
    }
  }
  .title {
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap;
    font-family: "poppins", "montserra";
    color: #333333;
  }
  .time {
    font-family: "poppins", "montserra";
    color: #666666;
  }
  .cardDesc {
    letter-spacing: 0px;
    font-size: 12px;
    font-family: "montserra";
  }
}
</style>