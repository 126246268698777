<template>
  <section class="ShareCourseCard">
    <!-- 积分商品     => 分享 -->
    <div class="chat-detail">
      <div class="chat-message-share cursor-pointer" @click="handleClickJudgment">
        <div class="d-flex">
          <div class="courseImg">
            <img :src="params.product_image" alt="" />
          </div>
          <div>
            <div class="mb-1 title" :title="params.product_name">
              {{ params.product_name }}
            </div>
            <div class="money">
              {{ params.points }} Points
            </div>
          </div>
        </div>
        <hr class="mb-2" />
        <p class="mb-0 cardDesc">Share a Product to you</p>
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    name: "",
    props: {
      params: {
        type: Object,
      },
    },

    data() {
      return {};
    },
    computed: {},
    watch: {},
    methods: {
      handleClickJudgment() {
        const data = {
          share_type: 8,
          share_id: this.params.id,
        };
        this.$http.sharedContentExist(data).then((res) => {
          if (res.status == 200) {
            if (res.data) {
              this.$router.push(`/shopscoredetails?id=${this.params.id}`);
            } else {
              this.$message.warning("This product has been deleted!");
            }
          }
        });
      },
    },
    created() { },
  };
</script>
<style src='' lang='scss' scoped>
  @media (min-width: 600px){
    .title{
      width: 210px;
    }
    .money {
      font-size: 12px;
    }
  }
  @media (max-width: 600px){
    .title{
      width: 70px;
    }
    .money {
      font-size: 8px;
    }
  }
  .ShareCourseCard {
    .courseImg {
      width: 60px;
      height: 60px;
      background: #f5fbff;
      border-radius: 5px;
      margin-right: 15px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
      }
    }

    .title {
      overflow: hidden; 
      text-overflow: ellipsis; 
      white-space: nowrap;
      font-family: "poppins", "montserra";
      color: #333333;
    }

    .money {
      font-family: "poppins", "montserra";
      color: #666666;
    }

    .cardDesc {
      letter-spacing: 0px;
      font-size: 12px;
      font-family: "montserra";
    }
  }
</style>