<template>
  <div class="chatContainer">
    <iq-card body-class="chat-page p-0">
      <template v-slot:body>
        <div class="chat-data-block">
          <b-row class="m-0">
            <b-col class="col-lg-3 col-4 chat-data-left p-0">
              <div class="chat-search pt-3 pl-3">
                <!-- 当前用户头像+nickname -->
                <ToggleButton toggleShow="#user-detail-popup">
                  <template v-slot:media>
                    <img :src="userInfo.avatar || defaultAvatar" alt="chat-user" class="avatar-60 cursor-pointer" />
                  </template>
                  <template v-slot:body>
                    <h5 class="mb-0 widthStyle text-overflow-single" :title="userInfo.nick_name">
                      {{ userInfo.nick_name }}
                    </h5>
                    <p></p>
                  </template>
                </ToggleButton>

                <!-- 左侧滑入显现的当前用户详情 -->
                <ToggleContent id="user-detail-popup" body-class="text-left overflow-scroll">
                  <template v-slot:media>
                    <!-- 头像 -->
                    <div class="friendSelfAvatar">
                      <img :src="userInfo.avatar" alt="avatar" />
                    </div>
                  </template>
                  <!-- 好友信息列表 -->
                  <template v-slot:body>
                    <!-- 姓名 -->
                    <div class="row">
                      <div class="col-6 col-md-6 title">Nickname:</div>
                      <div class="col-6 col-md-6 valInfo">
                        {{ userInfo.nick_name }}
                      </div>
                    </div>
                    <hr />
                    <!-- 性别 -->
                    <div class="row">
                      <div class="col-6 col-md-6 title">Gender:</div>
                      <div class="col-6 col-md-6 valInfo">
                        {{ userInfo.sex | filterGender }}
                      </div>
                    </div>
                    <hr />
                    <div class="row" style="cursor:pointer" @click="openPopup">
                      <div class="col-6 col-md-6 title">Introduce:</div>
                      <div v-if="userInfo.motto" class="col-6 col-md-6 valInfo"
                        style="max-width:160px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">
                        <el-tooltip class="item" effect="light" :content="userInfo.motto" placement="bottom"
                          :visible-arrow="false">
                          <span>{{ userInfo.motto }}</span>
                        </el-tooltip>
                      </div>
                      <div v-else class="col-6 col-md-6 valInfo">
                        -
                      </div>
                    </div>
                    <hr />
                  </template>
                </ToggleContent>
                <!-- 搜索好友 -->
                <div class="mx-2 mt-4">
                  <el-input type="text" suffix-icon="el-icon-search" clearable v-model="searchList"
                    @change="getChatRoomList()" placeholder="Search" />
                </div>
              </div>
              <!-- 左侧 => 聊天好友列表 -->
              <div class="chat-sidebar-channel scroller mt-4 pl-3">
                <div class="iq-chat-ui" id="chat-list-data">
                  <template v-for="(item, index) in usersList">
                    <div :key="index" v-if="item.user_id != undefined" class="cursor-pointer borderStyle"
                      @click="isShow = `tab-friend${item.user_id}`" :class="isShow == `tab-friend${item.user_id}`
                                ? 'tabActive'
                                : ''
                            ">
                      <!-- 单条好友 -->
                      <div @click="closeAllPopup">
                        <ChatItem :item="item" @clickItemInfo="getClickItemInfo" />
                      </div>
                    </div>
                    <div :key="index" class="cursor-pointer borderStyle" @click="isShow = `tab-group${item.group_id}`"
                      :class="isShow == `tab-group${item.group_id}`
                                ? 'tabActive'
                                : ''
                            " v-else>
                      <!-- 群组 -->
                      <div @click="closeAllPopup">
                        <GroupChatItem :item="item" @clickItemInfo="getClickItemInfo" />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </b-col>
            <!-- 右侧聊天内容区 -->
            <div class="col-lg-9 col-8 chat-data p-0 chat-data-right"
              :style="`background: url(${require('@/assets/images/page-img/100.jpg')}) no-repeat scroll center center;background-size: cover;`">
              <div>
                <!-- 当没有选中聊天对象时 =>展示背景墙 -->
                <div v-if="isShow == ''">
                  <template>
                    <ToggleButton :mini-toggle="true" :close-button="false" toggleShow="#chat-user-detail-popup"
                      mediaClass="chat-user-profile">
                    </ToggleButton>
                    <div class="chat-start">
                      <div class="chat-start">
                        <span class="iq-start-icon text-primary"><i class="ri-message-3-line"></i></span>
                        <button id="chat-start" class="btn bg-white mt-3">
                          Start Conversation!
                        </button>
                      </div>
                    </div>
                  </template>
                </div>
                <!-- 当选中聊天对象时 展示与该聊天对象的内容 -->
                <div v-else>
                  <template>
                    <!-- 右侧 头部 -->
                    <div class="chat-head">
                      <header class="d-flex
                                justify-content-between
                                align-items-center
                                bg-white
                                pt-3
                                pr-3
                                pb-3
                              ">
                        <!-- ////////////////////////// 好友 ///////////////// -->
                        <!-- 头部该聊天对象的 头像 及 操作 -->
                        <ToggleButton :mini-toggle="true" :close-button="false" toggleShow="#chat-user-detail-popup"
                          mediaClass="chat-user-profile" v-if="currentChatUser.type == 'friend'"
                          toggleClose="#chat-user-info">
                          <template v-slot:media>
                            <!-- 头像 -->
                            <el-avatar :size="50" shape="square" :src="friendData.avatar" @error="true"
                              class="cursor-pointer">
                              <img src="@/assets/images/public/user.png" />
                            </el-avatar>
                            <!-- 是否在线 => 绿色小圆点 -->
                            <span class="avatar-status"><i class="ri-checkbox-blank-circle-fill text-success"
                                v-if="friendData.is_online == 1" /></span>
                          </template>
                          <!-- 名字 -->
                          <template v-slot:body>
                            <h5 class="mb-0 chatName" style="max-width:450px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">
                              {{ friendData.nick_name }}
                            </h5>
                          </template>
                        </ToggleButton>

                        <!-- 当前聊天对象头像弹出  => 好友个人详情详情弹窗 -->
                        <ToggleContent id="chat-user-detail-popup" bodyClass="chatuser-detail" center
                          ref="myFriendToggleContent">
                          <template v-slot:media>
                            <!-- 头像 -->
                            <div class="friendSelfAvatar cursor-pointer">
                              <img @click="$router.push({path: '/profile',query: {user_id: friendData.user_id,},})"
                                :src="friendData.avatar" alt="avatar" />
                            </div>
                          </template>
                          <!-- 好友信息列表 -->
                          <template v-slot:body>
                            <!-- 姓名 -->
                            <div class="row pl-3">
                              <div class="col-6 col-md-6 title">Nickname:</div>
                              <div class="col-6 col-md-6 valInfo overHidden cursor-pointer"
                                @click="$router.push({path: '/profile',query: {user_id: friendData.user_id,},})">
                                <el-tooltip class="item" effect="light" :content="friendDetail.nick_name"
                                  placement="top" :visible-arrow="false">
                                  <span>{{ friendDetail.nick_name }}</span>
                                </el-tooltip>
                              </div>
                            </div>
                            <hr />
                            <!-- 电话号码 -->
                            <!-- <div class="row">
                                    <div class="col-6 col-md-6 title">Tel:</div>
                                    <div class="col-6 col-md-6">
                                      {{ friendDetail.phone | filterPhonen }}
                                    </div>
                                  </div>
                                  <hr /> -->
                            <!-- 生日 -->
                            <!-- <div class="row">
                                    <div class="col-6 col-md-6 title">
                                      Date Of Birth:
                                    </div>
                                    <div class="col-6 col-md-6">
                                      <span v-if="friendDetail.birthday">
                                        {{ friendDetail.birthday }}
                                      </span>
                                      <span v-else>--</span>
                                    </div>
                                  </div>
                                  <hr /> -->
                            <!-- 性别 -->
                            <!-- <div class="row">
                                    <div class="col-6 col-md-6 title">Gender:</div>
                                    <div class="col-6 col-md-6 valInfo">
                                      {{ friendDetail.sex | filterGender }}
                                    </div>
                                  </div>
                                  <hr /> -->
                            <div class="row pl-3">
                              <div class="col-6 col-md-6 title">Introduce:</div>
                              <div v-if="friendDetail.motto" class="col-6 col-md-6 valInfo"
                                style="max-width:160px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">
                                <el-tooltip class="item" effect="light" :content="friendDetail.motto" placement="bottom"
                                  :visible-arrow="false">
                                  <span>{{ friendDetail.motto }}</span>
                                </el-tooltip>
                              </div>
                              <div v-else class="col-6 col-md-6 valInfo">-</div>
                            </div>
                            <div class="row ml-4 mt-5">
                              <div class="col-6 col-md-6">
                                <b-button variant="outline-primary" v-if="!friendDetail.is_friend"
                                  @click="friendRequest(friendData.user_id)">
                                  + Friend
                                </b-button>
                                <b-button v-else variant="outline-primary" style="pointer-events: none;
                                                                        cursor: default;
                                                                        background: #a09e9e;
                                                                        border: #a09e9e;
                                                                        color: #fff;
                                                                      ">
                                  + Friend
                                </b-button>
                              </div>
                              <div class="col-6 col-md-6">
                                <b-button variant="primary"
                                  @click="$router.push({path: '/profile',query: {user_id: friendData.user_id,},})">
                                  View profile
                                </b-button>
                              </div>
                            </div>
                            <!-- <hr /> -->
                          </template>
                        </ToggleContent>

                        <!-- ////////////////////群聊////////////////// -->

                        <!-- 当前聊天对象头像以及操作弹出  => 群内好友列表 -->
                        <ToggleButton :mini-toggle="true" :close-button="false" toggleShow="#chat-group-detail-popup"
                          mediaClass="chat-user-profile" v-if="currentChatUser.type == 'group'"
                          toggleClose="#chat-user-info">
                          <template v-slot:media>
                            <!-- 头像 -->
                            <img :src="groupData.avatar?groupData.avatar:groupData.group_type==1?defaultOrg: defaultAvatar" alt="avatar" class="avatar-50" />
                            <!-- 是否在线 => 绿色小圆点 -->
                            <span class="avatar-status" v-if="currentChatUser.type == 'friend'"><i class="
                                        ri-checkbox-blank-circle-fill
                                        text-success
                                      " /></span>
                          </template>
                          <!-- 名字 -->
                          <template v-slot:body>
                            <h5 class="mb-0 chatName"
                              style="max-width:450px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">
                              {{ groupData.group_name }}
                            </h5>
                          </template>
                        </ToggleButton>
                        <!-- 群聊对象头像弹出  => 弹窗 -->
                        <ToggleContent id="chat-group-detail-popup" bodyClass="chatuser-detail"
                          ref="myGroupToggleContent">
                          <template v-slot:title>
                            <!-- 成员头像 -->
                            <el-row v-if="groupFriendList.length > 0"
                              style="width:360px;height:500px;padding:10px 0 0 10px;;overflow: auto;">
                              <!-- v-show="index < friendNum" -->
                              <!-- 新增群成员 -->
                              <el-col v-if="groupType!=1" :span="6">
                                <div style="margin:0 auto;" class="avatarBox addFrendBox cursor-pointer"
                                  @click="handleAddModifyPopup('add')">
                                  <span class="mt-1">+</span>
                                </div>
                              </el-col>
                              <el-col :span="6" v-if="judgeUserGroupleader &&groupType!=1">
                                <!-- 踢出群成员  if (current == 群主) true-->
                                <div style="margin:0 auto;" class="avatarBox addFrendBox cursor-pointer"
                                  @click="handleAddModifyPopup('del')">
                                  <span class="mt-1">-</span>
                                </div>
                              </el-col>

                              <el-col :span="6" v-for="(item, index) in groupFriendList" :key="index">
                                <div style="width:100%;sursor:pointer;"
                                  @click="$router.push({path: '/profile',query: {user_id: item.user_id}})">
                                  <div style="margin:0 auto;" class="avatarBox">
                                    <img :src="item.avatar" alt="" />
                                  </div>
                                  <p
                                    style="width:50px;margin:0 auto;color:ccc;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                                    {{item.nick_name}}
                                  </p>
                                </div>
                              </el-col>
                            </el-row>

                            <el-row v-if="groupFriendList.length > 0 &&getGroupUserListTotal>50"
                              class="d-flex justify-content-center mt-3 operation">
                              <div @click="nextFriendPage">
                                more
                                <i class="ri-arrow-down-s-line ml-2"></i>
                              </div>
                            </el-row>
                            <!-- <el-row v-else style="text-align:center;color: red"  class="mt-5" >
                            The current group chat has been disbanded
                        </el-row> -->
                          </template>
                          <template v-slot:body>
                            <div class="delete text-center" v-if="groupFriendList.length > 0 && groupType!=1"
                              @click="exitGroup">
                              Delete and exit
                            </div>
                          </template>
                        </ToggleContent>

                        <!-- 点击聊天框对方头像 -->
                        <ToggleContent id="chat-user-info" bodyClass="chatuser-detail">
                          <template v-slot:media>
                            <!-- 头像 -->
                            <div class="friendSelfAvatar cursor-pointer">
                              <img :src="friendDetail.avatar"
                                @click="$router.push({path: '/profile',query: {user_id: friendDetail.user_id,}})"
                                alt="avatar" />
                            </div>
                          </template>

                          <!-- 好友信息列表 -->
                          <template v-slot:body>
                            <!-- 姓名 -->
                            <div class="row">
                              <div class="col-6 col-md-6 title">
                                Nickname:
                              </div>
                              <div class="col-6 col-md-6 overHidden cursor-pointer"
                                @click="$router.push({path: '/profile',query: {user_id: friendDetail.user_id,}})">
                                <el-tooltip class="item" effect="light" :content="friendDetail.nick_name"
                                  placement="top" :visible-arrow="false">
                                  <span>{{ friendDetail.nick_name }}</span>
                                </el-tooltip>
                              </div>
                            </div>
                            <hr />
                            <div class="row">
                              <div class="col-6 col-md-6 title">Introduce:</div>
                              <div v-if="friendDetail.motto" class="col-6 col-md-6 valInfo"
                                style="max-width:160px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">
                                <el-tooltip class="item" effect="light" :content="friendDetail.motto" placement="bottom"
                                  :visible-arrow="false">
                                  <span>{{ friendDetail.motto }}</span>
                                </el-tooltip>
                              </div>
                              <div v-else class="col-6 col-md-6 valInfo">-</div>
                            </div>

                            <div class="row mt-5">
                              <div class="col-6 col-md-6">
                                <b-button variant="outline-primary" v-if="!friendDetail.is_friend"
                                  @click="friendRequest(friendDetail.user_id)">
                                  + Friend
                                </b-button>
                                <b-button v-else variant="outline-primary" style="pointer-events: none;
                                          cursor: default;
                                          background: #a09e9e;
                                          border: #a09e9e;
                                          color: #fff;
                                        ">
                                  + Friend
                                </b-button>
                              </div>
                              <div class="col-6 col-md-6">
                                <b-button variant="primary"
                                  @click="$router.push({path: '/profile',query: {user_id: friendDetail.user_id,},})">
                                  View profile
                                </b-button>
                              </div>
                            </div>
                          </template>
                        </ToggleContent>

                        <!-- 操作图标 好友详情弹框 -->
                        <div class="chat-header-icons d-flex">
                          <ToggleButton :mini-toggle="true" :close-button="false" toggleShow="#chat-user-detail-popup"
                            mediaClass="chat-user-profile" v-if="currentChatUser.type == 'friend'">
                            <template v-slot:media>
                              <a class="iq-waves-effect mr-1 chat-icon-delete"><i class="
                                          ri-settings-4-line
                                          mr-0
                                          cursor-pointer
                                        " style="font-size: 22px"></i></a>
                            </template>
                          </ToggleButton>
                          <ToggleButton :mini-toggle="true" :close-button="false" toggleShow="#chat-group-detail-popup"
                            mediaClass="chat-user-profile" v-if="currentChatUser.type == 'group'"
                            @clickSystemBtn="getGroupUserList(groupData.group_id,1)">
                            <template v-slot:media>
                              <a class="iq-waves-effect mr-1 chat-icon-delete">
                                <i class="
                                          ri-settings-4-line
                                          mr-0
                                          cursor-pointer
                                        " style="font-size: 22px"></i>
                              </a>
                            </template>
                          </ToggleButton>
                          <!-- 公共 -->
                          <span class="" v-if="groupType!=1">
                            <i @click="delChatMsg" class="ri-delete-bin-line cursor-pointer" style="color: var(--iq-primary);
                                      font-size: 22px;
                                    "></i>
                          </span>
                        </div>
                      </header>
                    </div>
                    <!-- 聊天内容列表 -->
                    <div class="chat-content scroller" ref="chatContent" @mousewheel="scrool">
                      <template v-for="(item, index) in messages">
                        <!-- 右边 -->
                        <div class="chat" :key="index" v-if="
                                  item.send_user_id == dataChat.user_id &&
                                  item.msg_type < 6
                                ">
                          <div class="chat-user">
                            <a class="avatar m-0">
                              <img :src="userInfo.avatar" alt="avatar" class="avatar-35" />
                            </a>
                            <span class="chat-time mt-1">{{ interceptTime(item.send_time) }}</span>
                          </div>
                          <div class="chat-detail">
                            <div v-if="item.msg_type==5" class="chat-message p-0">
                              <img :src="item.content" style="width:100px;height:100px" alt="Sticker" />
                            </div>
                            <div v-else class="chat-message">
                              <i class="el-icon-loading" v-if="loadingIcon(item)"></i>
                              <p style="word-break:keep-all">
                                {{ item.content }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- 右边     => 分享 -->
                        <div class="chat" :key="index" v-if="
                                  item.send_user_id == dataChat.user_id &&
                                  item.msg_type == 10
                                ">
                          <div class="chat-user">
                            <a class="avatar m-0">
                              <img :src="userInfo.avatar" alt="avatar" class="avatar-35" />
                            </a>
                            <span class="chat-time mt-1">{{ interceptTime(item.send_time) }}</span>
                          </div>

                          <!-- 发帖  类型待指定................. -->
                          <ShareAwardCard v-if="getCurrentShareType(item.content) == 7"
                            :params="transformParams(item)" />
                          <SharePostCard v-if="getCurrentShareType(item.content) == 9"
                            :params="transformParams(item)" />
                          <ShareProjectCard v-if="getCurrentShareType(item.content) == 5"
                            :params="transformParams(item)" />
                          <ShareEventCard v-if="getCurrentShareType(item.content) == 4"
                            :params="transformParams(item)" />
                          <ShareMeetingCard v-if="getCurrentShareType(item.content) == 2"
                            :params="transformParams(item)" />

                          <ShareCourseCard v-if="getCurrentShareType(item.content) == 3"
                            :params="transformParams(item)" />
                          <SharePointProductCard v-if="getCurrentShareType(item.content) == 8"
                            :params="transformParams(item)" />
                          <ShareClubCard v-if="getCurrentShareType(item.content) == 11"
                            :params="transformParams(item)" />
                        </div>

                        <!-- 左边 -->
                        <div class="chat chat-left" :key="index" v-if="
                                  item.send_user_id != dataChat.user_id &&
                                  item.msg_type < 6
                                ">
                          <div class="chat-user">
                            <!-- 头像 -->
                            <!-- <a class="avatar m-0">
                                    <img :src="judgeChatType(item)" alt="avatar" class="avatar-35" />
                                  </a> -->
                            <ToggleButton :mini-toggle="true" :close-button="false" toggleShow="#chat-user-info"
                              mediaClass="chat-user-info" class="mr-0" toggleClose="#chat-user-detail-popup"
                              toggleGroup="#chat-group-detail-popup">
                              <template v-slot:media>
                                <img :src="judgeChatType(item)" alt="avatar" class="avatar-35 cursor-pointer"
                                  @click="handleClickItemFriend(item)" />
                              </template>
                            </ToggleButton>

                            <!-- 时间 -->
                            <span class="chat-time mt-1">{{ interceptTime(item.send_time) }}</span>
                          </div>
                          <div class="chat-detail">
                            <div class="chat-message" v-if="item.msg_type == 1">
                              <p>{{ item.content }}</p>
                            </div>
                          </div>
                          <div class="chat-detail">
                            <div class="chat-message p-0" v-if="item.msg_type == 5">
                              <p>
                                <img style="width:100px;height:100px" :src="item.content" alt="Sticker" />
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- 左边     => 分享 -->
                        <div class="chat chat-left" :key="index" v-if="
                                  item.send_user_id != dataChat.user_id &&
                                  item.msg_type == 10
                                ">
                          <div class="chat-user">
                            <!-- 头像 -->
                            <!-- <a class="avatar m-0">
                                    <img :src="judgeChatType(item)" alt="avatar" class="avatar-35" />
                                  </a> -->

                            <ToggleButton :mini-toggle="true" :close-button="false" toggleShow="#chat-user-info"
                              mediaClass="chat-user-info" class="mr-0" toggleClose="#chat-user-detail-popup"
                              toggleGroup="#chat-group-detail-popup">
                              <template v-slot:media>
                                <img :src="judgeChatType(item)" alt="avatar" class="avatar-35 cursor-pointer"
                                  @click="handleClickItemFriend(item)" />
                              </template>
                            </ToggleButton>
                            <!-- 时间 -->
                            <span class="chat-time mt-1">{{ interceptTime(item.send_time) }}</span>
                          </div>
                          <div class="chat-message-left">
                            <ShareAwardCard v-if="getCurrentShareType(item.content) == 7"
                              :params="transformParams(item)" />
                            <!-- 发帖  类型暂定 =>  9................. -->
                            <SharePostCard v-if="getCurrentShareType(item.content) == 9"
                              :params="transformParams(item)" />
                            <ShareProjectCard v-if="getCurrentShareType(item.content) == 5"
                              :params="transformParams(item)" />
                            <ShareEventCard v-if="getCurrentShareType(item.content) == 4"
                              :params="transformParams(item)" />
                            <ShareMeetingCard v-if="getCurrentShareType(item.content) == 2"
                              :params="transformParams(item)" />
                            <ShareCourseCard v-if="getCurrentShareType(item.content) == 3"
                              :params="transformParams(item)" />
                            <SharePointProductCard v-if="getCurrentShareType(item.content) == 8"
                              :params="transformParams(item)" />
                            <ShareClubCard v-if="getCurrentShareType(item.content) == 11"
                              :params="transformParams(item)" />
                          </div>
                        </div>

                        <!-- 日期显示 -->
                        <div class="d-flex justify-content-center" :key="index" v-if="item.msg_type == 999">
                          <div class="promptMessage">
                            <div>{{ item.showTimer }}</div>
                          </div>
                        </div>

                        <!-- 系统提示消息 -->
                        <div class="d-flex justify-content-center" :key="index" v-if="
                                  item.msg_type >= 6 &&
                                  item.msg_type <= 13 &&
                                  item.msg_type != 10
                                ">
                          <div class="promptMessage">
                            <div>{{ item.content }}</div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <!-- 底部  => send 发送聊天文字 -->
                    <div class="chat-footer bg-white pt-3 pb-3 pr-3">
                      <form class="d-flex align-items-center" action="javascript:void(0);">
                        <div class="chat-attagement d-flex">
                          <!-- 表情 -->
                          <el-popover placement="bottom" trigger="click" width="380">
                            <div class="sticker" style="height: 280px;overflow-y:scroll;">
                              <div class="emoji_box" v-if="emoji_type==1">
                                <Emoji @selectEmoji="selectEmoji" />
                              </div>
                              <div class="sticker_box" v-if="emoji_type==2">
                                <img v-for="(v, i) of stickerList" @click="seleSticker(v.url)" :key="i" :src="v.url"
                                  style="width:80px;height:80px;margin:5px" alt="" />
                              </div>
                            </div>
                            <div class="emoji_btn">
                              <a href="javascript:void(0);" @click="emoji_type=1">
                                <i class="ri-emotion-happy-line pr-3" :class="{grey_emoji:emoji_type==2}"
                                  style="font-size: 20px;"></i>
                              </a>
                              <a href="javascript:void(0);" @click="emoji_type=2">
                                <!-- <i class="ri-emotion-normal-line pr-3" :class="{grey_emoji:emoji_type==1}"
                                        style="font-size: 20px;"></i> -->
                                <img v-if="emoji_type==1" style="width:18px;height:18px"
                                  src="@/assets/images/icon/sticker.png" alt="" />
                                <img v-else style="width:18px;height:18px" src="@/assets/images/icon/sticker-blue.png"
                                  alt="" />
                              </a>
                            </div>
                            <a slot="reference" href="javascript:void(0)"><i class="fa fa-smile-o pl-3 pr-3"
                                aria-hidden="true"></i></a>
                          </el-popover>
                          <!-- <Emoji @selectEmoji="selectEmoji"/> -->
                          <!-- 分享 -->
                          <!-- <a href="javascript:void(0)"
                                  ><i
                                    class="fa fa-paperclip pr-3"
                                    aria-hidden="true"
                                  ></i
                                ></a> -->
                        </div>
                        <input type="text" class="form-control mr-3" placeholder="Type your message" v-model="content"
                          maxlength="2048" />
                        <!-- Send => 发送 -->
                        <button type="submit" class="
                                  btn btn-primary
                                  d-flex
                                  align-items-center
                                  p-2
                                " @click="handleClickSend">
                          <i class="far fa-paper-plane"></i><span class="d-none d-lg-block ml-1">Send</span>
                        </button>
                      </form>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </b-row>
        </div>
      </template>
      <!-- 添加群友和删除群友 => 弹窗 -->
      <b-modal id="addModifyPopup" centered title="Creat Group chat" hide-footer>
        <div v-loading="invitePopLoading" class="row m-0 h-100">
          <div class="col-5 contentLeft border-right">
            <!-- 搜索框 -->
            <div class="mb-3 d-flex flex-grow-1 searchBox" v-if="currentShowPopupType!='del'">
              <el-input type="text" suffix-icon="el-icon-search" clearable v-model="groupUser"
                @change="groupUserSearch()" placeholder="Search Group" />
              <!-- <img @click="groupUserSearch()" src="@/assets/images/course/search.png" alt="" /> -->
            </div>
            <!-- 好友列表 -->
            <ul class="p-0">
              <li class="d-flex justify-content-between mb-3" v-for="(item, index) in operateFriendsList" :key="index">
                <div class="d-flex align-items-center">
                  <div class="imgBox">
                    <img :src="item.avatar" alt="" />
                  </div>
                  <div class="name">{{ item.nick_name }}</div>
                </div>
                <div class="d-flex align-items-center">
                  <input type="checkbox" @click="addUserList(item)" class="form-check-input mr-3 mb-1"
                    v-model="item.select" :disabled="item.lock" />
                </div>
              </li>
            </ul>
          </div>
          <div class="
                    col-7
                    d-flex
                    flex-column
                    justify-content-between
                    contentRight
                  ">
            <div>
              <div class="title">
                Please check the contacts you need to add.
              </div>
              <!-- 选中的添加好友 -->
              <ul class="p-0">
                <li class="mb-3 d-flex justify-content-between" v-for="(item, i) in filterSelectFriend" :key="i">
                  <div class="d-flex align-items-center">
                    <div class="imgBox">
                      <img :src="item.avatar" alt="" />
                    </div>
                    <div class="name">{{ item.nick_name }}</div>
                  </div>
                  <div class="d-flex align-items-center cursor-pointer" @click="removeSelect(item,i)">
                    <img src="@/assets/images/public/close-s.png" alt="" />
                  </div>
                </li>
              </ul>
            </div>
            <div class="d-flex justify-content-end">
              <b-button variant="primary" class="btnOrange mr-2" @click="$bvModal.hide('addModifyPopup')">
                Cancel
              </b-button>
              <b-button variant="primary" @click="handlePostType">Confirm</b-button>
            </div>
          </div>
        </div>
      </b-modal>
    </iq-card>
    <b-modal id="mottoPopup" centered title="Edit Motto" hide-footer>
      <div class="form-group">
        <label>Motto</label>
        <input type="text" v-model="motto" class="form-control" />
      </div>
      <button type="button" class="btn btn-primary mt-2 float-right" @click="editMotto">
        {{ $t("MyJCI.f2c@submit") }}
      </button>
    </b-modal>
  </div>
</template>
<script>
  import ChatItem from '@/components/Chat/ChatItem'
  import GroupChatItem from '@/components/Chat/GroupChatItem'
  import ToggleButton from '@/components/Chat/ToggleButton'
  import ToggleContent from '@/components/Chat/ToggleContent'
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex'
  import defaultAvatar from '@/assets/images/public/user.png'
  import defaultOrg from '@/assets/images/channel/org1.jpg'

  import ShareAwardCard from './components/ShareAwardCard'
  import SharePostCard from './components/SharePostCard'
  import ShareCard from './components/ShareCard'
  import ShareCourseCard from './components/ShareCourseCard'
  import ShareEventCard from './components/ShareEventCard'
  import ShareMeetingCard from './components/ShareMeetingCard'
  import ShareProjectCard from './components/ShareProjectCard'
  import ShareChannelCard from './components/ShareChannelCard'
  import SharePointProductCard from './components/SharePointProductCard'
  import ShareClubCard from './components/ShareClubCard'
  import Emoji from './components/Emoji'

  export default {
    name: 'Chat',
    components: {
      ChatItem,
      GroupChatItem,
      ToggleButton,
      ToggleContent,
      ShareAwardCard,
      SharePostCard,
      ShareCard,
      ShareCourseCard,
      ShareEventCard,
      ShareMeetingCard,
      ShareProjectCard,
      ShareChannelCard,
      SharePointProductCard,
      ShareClubCard,
      Emoji
    },
    inject: ['reload'],
    data() {
      return {
        emoji_type: 1,
        scrollHeight: 0,
        sticker: '',
        friendDetail: {},
        timestampList: [],
        timeList: [], // 聊天界面时间列表
        isShow: '',
        usersList: [],
        isRunning: false,
        currentPageNum: 1,
        isShowRetract: false,
        friendNum: 0,
        defaultAvatar, // 默认头像
        defaultOrg,
        seeMoreShow: false, // 显示更多群友
        currentChatUser: {
          type: '',
          id: '',
          toatlPage: 1
        }, // 当前聊天的界面
        active: true,
        search: '', // 搜索好友
        friendSelfInfo: {}, // 好友信息详情
        messageContent: {}, // 聊天内容
        content: '', // 要发送的内容
        friendData: {}, // 好友详情数据
        groupData: {}, // 群组详情数据
        groupFriendList: [], // 群组好友列表
        getGroupUserListTotal: 0,
        currentgGoupFriendID: [], // 当前群友 id
        operateFriendsList: [], // 添加群友 && 踢除群友
        currentShowPopupType: '', // 当前显示的弹出类型   'add'  || 'edit'
        editGroupData: {
          group_name: '' // 群组名称
        },
        groupUser: '', //添加好友到群组时搜索好友
        stickerList: [],

        newsCorner: {
          // 消息角标
          groupInfoNum: [],
          friendsInfoNum: []
        },

        motto: '',
        searchList: '', //聊天列表搜索

        filterSelectFriend: [],
        filterId: [],

        // 群好友列表分页
        page: 1,
        currentUserOrGroupId: 0, //好友id
        groupType: 0, //群组类型

        // 邀请好友进群loding
        invitePopLoading: false
      }
    },
    watch: {
      // 监听是否有新消息
      'dataChat.chatMessage': {
        deep: true,
        handler: function (newData, oldData) {
          let {
            type,
            id
          } = this.currentChatUser
          if (
            type == 'friend' &&
            id == newData.send_user_id &&
            newData.type != 'sendGroup'
          ) {
            let time = this.timeIntercept(newData.send_time)
            if (!this.timeList.includes(time)) {
              this.timeList.push(time)
              this.messageContent[`friend-${id}`].push({
                msg_type: '999',
                showTimer: time
              })
            }
            if (newData.msg_type != 10)
              this.messageContent[`friend-${id}`].push(newData)
          } else if (type == 'group' && id == newData.push_group_id) {
            // newData.send_time = new Date().Format("yyyy-MM-dd hh:mm:ss")
            if (!newData.id) newData.send_status = this.timestamp
            let time = this.timeIntercept(newData.send_time)
            if (!this.timeList.includes(time)) {
              this.timeList.push(time)
              this.messageContent[`group-${id}`].push({
                msg_type: '999',
                showTimer: time
              })
            }
            if (newData.msg_type != 10)
              this.messageContent[`group-${id}`].push(newData)
            setTimeout(() => {
              this.timestampList.splice(
                this.timestampList.indexOf(this.timestamp),
                1
              )
            }, 200)
          }
          let message = JSON.parse(JSON.stringify(newData))
          this.usersList.forEach((v, i) => {
            let item = v
            if (
              v.user_id &&
              v.user_id == newData.send_user_id &&
              newData.type != 'sendGroup'
            ) {
              this.usersList.splice(i, 1)
              this.usersList.unshift(item)
            } else if (
              newData.type === 'sendGroup' &&
              v.group_id == newData.push_group_id
            ) {
              this.usersList.splice(i, 1)
              this.usersList.unshift(item)
            }

            if (
              v.user_id == newData.send_user_id &&
              newData.type != 'sendGroup'
            ) {
              this.$set(v, 'last_msg', message.content)
              if (newData.send_user_id != id) {
                let subscript = Number(v.subscript)
                let num = subscript + 1
                this.$set(v, 'subscript', num)
              } else {
                const ws = window.GlobalWebSocket
                ws.send(
                  JSON.stringify({
                    type: 'sendFriend',
                    friend_id: newData.send_user_id
                  })
                )
              }
            }
            if (
              v.group_id == newData.push_group_id &&
              newData.type == 'sendGroup' &&
              id != newData.push_group_id
            ) {
              this.$set(v, 'last_msg', message.content)
              let subscript = Number(v.subscript)
              let num = subscript + 1
              this.$set(v, 'subscript', num)
            } else if (
              v.group_id == newData.push_group_id &&
              newData.type == 'sendGroup' &&
              id == newData.push_group_id
            ) {
              this.$set(v, 'last_msg', message.content)
              const ws = window.GlobalWebSocket
              ws.send(
                JSON.stringify({
                  type: 'sendGroup',
                  sendGroup: newData.push_group_id
                })
              )
            }
          })
          this.$nextTick().then(() => {
            if (this.$refs.chatContent) {
              this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight
            }
          })
        }
      },
      showCurrentChat: {
        deep: true,
        immediate: true,
        handler: async function (newData, oldData) {
          this.groupId = newData.group_id
          this.groupType = await this.getChatRoomList()
          if (newData.isSidebar) {
            let arr = []
            this.usersList.forEach((v, i) => {
              if (v.user_id == newData.user_id && !newData.group_id) {
                v.subscript = 0
                arr.push('测试')
                this.usersList.splice(i, 1)
                this.usersList.unshift(v)
              } else if (v.group_id == newData.group_id && !newData.user_id) {
                arr.push('测试')
                v.subscript = 0
                this.usersList.splice(i, 1)
                this.usersList.unshift(v)
              }
            })
            if (arr.length == 0) {
              if (newData.group_id) {
                const data = {}
                this.$http.getChatRoomList(data).then(res => {
                  if (res.status == 200) {
                    this.usersList = res.data
                    this.usersList.unshift({
                      avatar: newData.avatar,
                      user_id: newData.group_id,
                      nick_name: newData.group_name,
                      last_msg: '',
                      last_msg_time: '',
                      subscript: 0
                    })
                  }
                })
              } else {
                const data = {}
                this.$http.getChatRoomList(data).then(res => {
                  if (res.status == 200) {
                    this.usersList = res.data
                    this.usersList.unshift({
                      avatar: newData.avatar,
                      user_id: newData.user_id,
                      nick_name: newData.nick_name,
                      last_msg: '',
                      last_msg_time: '',
                      subscript: 0
                    })
                  }
                })
              }
            }
            if (newData.isLink) {
              const data = {
                client_id: this.dataChat.client_id
              }
              this.$http.getChatRoomList(data).then(res => {
                if (res.status == 200) {
                  this.usersList = res.data
                  this.usersList.unshift({
                    avatar: newData.avatar,
                    user_id: newData.user_id,
                    nick_name: newData.nick_name,
                    last_msg: '',
                    last_msg_time: '',
                    subscript: 0
                  })
                }
              })
            }

            this.messageContent = {}
            // 切换 , 清空发送输入框内容
            this.content = ''
            // 切换后重置页码
            this.currentPageNum = 1
            // 清空 聊天列表
            this.messageContent = {}
            this.$nextTick(() => {
              setTimeout(() => {
                this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight
              }, 1500)
            })
            if (newData.user_id != undefined) {
              this.isShow = `tab-friend${newData.user_id}`
              this.currentChatUser = {
                type: 'friend',
                id: newData.user_id
              }
              this.friendData = newData
              // 点击获取当前好友聊天记录
              this.getOfflineFriendsMessage(newData.user_id)
              this.getFriendInfo(newData.user_id)
            } else {
              this.isShow = `tab-group${newData.group_id}`
              this.currentChatUser = {
                type: 'group',
                id: newData.group_id
              }
              // 获取群聊天记录
              this.getOfflineGroupMessage(newData.group_id)
              this.groupData = JSON.parse(JSON.stringify(newData))
              this.editGroupData.group_name = this.groupData.group_name
              this.getGroupUserList(newData.group_id)
            }
          }
        }
      },

    },
    computed: {
      // 获取当前用户相关信息
      ...mapGetters({
        userInfo: 'Public/getUserInfo',
        dataChat: 'ChatRoom/getDataChat',
        showCurrentChat: 'ChatRoom/getShowCurrentChat'
      }),
      // 当前群聊人数
      peopleNumber() {
        return this.groupFriendList.length
      },

      // 过滤出选中的要添加群聊的好友
      // filterSelectFriend() {
      //   let friendsList = [];
      //   this.operateFriendsList.forEach((v, i) => {
      //     if (v.select && !v.lock) {
      //       friendsList.push(v);
      //     }
      //   });
      //   return friendsList;
      // },
      // 判断用户是不是当前群的群主
      judgeUserGroupleader() {
        let groupFriendList = JSON.parse(JSON.stringify(this.groupFriendList))
        if (groupFriendList.length > 0) {
          let groupLeader = groupFriendList.shift()
          return groupLeader.user_id == this.dataChat.user_id
        }
      },
      messages() {
        let {
          type,
          id
        } = this.currentChatUser
        if (type == 'friend') {
          return this.messageContent[`friend-${id}`]
        } else if (type == 'group') {
          return this.messageContent[`group-${id}`]
        }
      }
    },
    mounted() {
      this.getChatEmoji()
      let light = document.getElementsByClassName('light')[0]
      let gradient = document.getElementsByClassName('gradient')[0]
      light.style.overflow = 'hidden'
      gradient.style.overflow = 'hidden'
    },
    methods: {
      ...mapActions({
        getUserInfo: 'Public/getUserInfo'
      }),
      openPopup() {
        this.motto = this.userInfo.motto
        this.$bvModal.show('mottoPopup')
      },
      editMotto() {
        this.$http
          .editUser({
            motto: this.motto
          })
          .then(res => {
            if (res.status == 200) {
              this.getUserInfo()
              this.$bvModal.hide('mottoPopup')
            }
          })
      },
      addUserList(item) {
        if (!item.select) {
          this.filterSelectFriend.push(item)
          this.filterId.push(item.user_id)
        } else {
          this.filterSelectFriend.forEach((v, i) => {
            if (v.user_id == item.user_id) {
              this.filterSelectFriend.splice(i, 1)
              this.filterId.splice(i, 1)
            }
          })
        }
      },
      // 邀请好友进群时搜索好友
      groupUserSearch() {
        //groupUser
        this.$http
          .userFriendList({
            keyword: this.groupUser,
            type: 4
          })
          .then(res => {
            if (res.status == 200) {
              res.data.forEach(v => {
                if (this.currentgGoupFriendID.includes(v.user_id)) {
                  this.$set(v, 'select', true)
                  this.$set(v, 'lock', true)
                } else if (this.filterId.includes(v.user_id)) {
                  this.$set(v, 'select', true)
                  this.$set(v, 'lock', false)
                } else {
                  this.$set(v, 'select', false)
                  this.$set(v, 'lock', false)
                }
              })
              this.operateFriendsList = res.data
            }
          })
      },
      async getChatEmoji() {
        let res = await this.$http.getChatEmoji()
        this.stickerList = res.data
      },
      ...mapMutations({
        CHANGE_DATA_CHAT: 'ChatRoom/CHANGE_DATA_CHAT',
        CHANGE_CURRENT_CHAT: 'ChatRoom/CHANGE_CURRENT_CHAT'
      }),
      // 添加好友
      friendRequest(user_id) {
        const data = {
          user_id
        }
        this.$http.friendRequest(data).then(res => {
          if (res.status == 200) {
            // this.$message.success(res.data.message);
          }
        })
      },
      // 删除聊天消息
      delChatMsg() {
        this.$confirm('Are you sure to delete the chat history?', 'Tips', {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          })
          .then(() => {
            const data = {
              type: this.currentChatUser.type,
              friend_id: this.currentChatUser.id,
              group_id: this.currentChatUser.id
            }
            this.$http.delChatMsg(data).then(res => {
              if (res.status == 200) {
                this.isShow = ''
                this.getChatRoomList()
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Deletion cancelled'
            })
          })
      },
      interceptTime(time) {
        if (time.includes('-')) {
          return time.substring(11, 16)
        } else {
          return time.substring(11, 17)
        }
      },
      // 转换聊天消息内容为 => {}
      transformParams(item) {
        let data = JSON.parse(item.content)
        return data.share_msg
      },
      getMobile(userInfo) {
        return `${userInfo.mobile_prefix}  ${userInfo.mobile}`
      },
      /**
       * 获取当前的分享类型    = > msg_type === 10
       *  4 => 活动
       *  2 => 会议
       *  5 => 项目
       *  7 => 奖项
       *  3 => 课程
       *  6 => 频道
       */
      getCurrentShareType(item) {
        let data = JSON.parse(item)
        return data.share_type
      },
      // 获取好友聊天列表角标
      async getFriendsSubscript() {
        const data = {
          client_id: this.dataChat.client_id
        }
        const res = await this.$http.getFriendsSubscript(data)
        if (res.status == 200) {
          this.friendsInfoNum = JSON.parse(JSON.stringify(res.data))
        }
      },
      // 获取好友及群组
      async getChatRoomList() {
        const data = {
          client_id: this.dataChat.client_id,
          keyword: this.searchList
        }
        let res = await this.$http.getChatRoomList(data)
        if (res.status == 200) {
          this.usersList = res.data
        }
      },
      // 聊天内容框滚动条事件
      scrool() {
        let {
          type,
          id,
          toatlPage
        } = this.currentChatUser
        let scrollTop = this.$refs.chatContent.scrollTop
        if (this.isRunning) {
          if (scrollTop < 60) {
            this.scrollHeight = this.$refs.chatContent.scrollHeight
          }
          if (type == 'friend' && scrollTop < 60) {
            this.currentPageNum += 1
            if (this.currentPageNum <= toatlPage) {
              this.getOfflineFriendsMessage(id)
              this.isRunning = false
            }
          } else if (type == 'group' && scrollTop < 60) {
            this.currentPageNum += 1
            if (this.currentPageNum <= toatlPage) {
              this.getOfflineGroupMessage(id)
              this.isRunning = false
            }
          }
        }
      },
      // 点击展开更多以及收起
      handleOperation(type) {
        if (type == 'more') {
          this.friendNum = this.groupFriendList.length
          this.isShowRetract = true
          this.seeMoreShow = false
        } else {
          this.judgingPeopleNum(this.groupFriendList)
        }
      },
      // 判断对象聊天对象类型并显示相应的头像
      judgeChatType(item) {
        if (item.avatar !== null) {
          let {
            type
          } = this.currentChatUser
          let headPortrait = ''
          type == 'group' ?
            (headPortrait = item.avatar) :
            (headPortrait = this.friendData.avatar)
          return headPortrait
        } else {
          return this.defaultAvatar
        }
      },
      // 获取好友聊天内容
      async getOfflineFriendsMessage(friends_id) {
        const data = {
          client_id: this.dataChat.client_id,
          friends_id,
          per_page: 10,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          page: this.currentPageNum
        }
        let res = await this.$http.getOfflineFriendsMessage(data)
        if (res.status == 200) {
          this.currentChatUser.toatlPage = res.data.last_page
          let arr = this.messageContent[`friend-${friends_id}`]
          let array = []
          if (arr) {
            arr.forEach(v => {
              array.push(v)
            })
          }
          let newArr = [...res.data.data, ...array]
          for (let index = 0; index < newArr.length; index++) {
            let v = newArr[index]
            let time = this.timeIntercept(v.send_time)
            if (time && !this.timeList.includes(time)) {
              this.timeList.push(time)
              newArr.splice(index, 0, {
                msg_type: '999',
                showTimer: time
              })
            }
          }
          this.$refs.chatContent.scrollTop = this.scrollHeight
          this.$set(this.messageContent, `friend-${friends_id}`, newArr)
          this.isRunning = true
        }
      },
      timeIntercept(time) {
        if (time) {
          return time.substring(0, 11)
        }
      },
      // 获取群组聊天内容
      async getOfflineGroupMessage(group_id) {
        const data = {
          client_id: this.dataChat.client_id,
          group_id,
          per_page: 10,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          page: this.currentPageNum
        }
        let res = await this.$http.getOfflineGroupMessage(data)
        if (res.status == 200) {
          this.currentChatUser.toatlPage = res.data.last_page
          let arr = this.messageContent[`group-${group_id}`]
          let array = []
          if (arr) {
            arr.forEach(v => {
              array.push(v)
            })
          }
          let newArr = [...res.data.data, ...array]
          for (let index = 0; index < newArr.length; index++) {
            let v = newArr[index]
            let time = this.timeIntercept(v.send_time)
            if (time && !this.timeList.includes(time)) {
              this.timeList.push(time)
              newArr.splice(index, 0, {
                msg_type: '999',
                showTimer: time
              })
            }
          }
          this.$refs.chatContent.scrollTop = this.scrollHeight
          this.$set(this.messageContent, `group-${group_id}`, newArr)
          this.isRunning = true
        }
      },
      // 关闭右边弹窗
      closePopup() {
        this.$refs.myGroupToggleContent.dismiss()
      },
      // 退出群聊
      exitGroup() {
        const data = {
          group_id: this.groupData.group_id,
          client_id: this.dataChat.client_id
        }
        this.$http.exitGroup(data).then(res => {
          if (res.status == 200) {
            // 强制刷新
            this.getUserGroup()
            this.isShow = ''
            this.reload()
          }
        })
      },
      // 判断新增群成员 || 踢除群成员
      handleAddModifyPopup(type) {
        this.currentShowPopupType = type
        this.filterSelectFriend = []
        this.filterId = []
        if (type == 'add') {
          // => 新增
          let friendsList = JSON.parse(
            JSON.stringify(this.dataChat.friendsList)
          )
          friendsList.forEach(v => {
            if (this.currentgGoupFriendID.includes(v.user_id)) {
              this.$set(v, 'select', true)
              this.$set(v, 'lock', true)
            } else {
              this.$set(v, 'select', false)
              this.$set(v, 'lock', false)
            }
          })
          this.operateFriendsList = friendsList
          this.$bvModal.show('addModifyPopup')
        } else {
          // = > 踢除
          let groupFriendList = JSON.parse(JSON.stringify(this.groupFriendList))
          groupFriendList.forEach(v => {
            if (v.user_id == this.dataChat.user_id) {
              this.$set(v, 'lock', true)
              this.$set(v, 'select', true)
            } else {
              this.$set(v, 'lock', false)
              this.$set(v, 'select', false)
            }
          })
          this.operateFriendsList = groupFriendList
          this.$bvModal.show('addModifyPopup')
        }
      },
      // 当前显示群友操作类型  新增 || 踢除
      handlePostType() {
        if (this.currentShowPopupType == 'add') {
          this.inviteFriendGroup()
        } else {
          this.removeGroupUser()
        }
      },
      // 移除选中的群友
      removeSelect(item, index) {
        this.filterSelectFriend.splice(index, 1)
        this.filterId.splice(index, 1)
        this.$set(item, 'select', false)
        this.operateFriendsList.forEach(v => {
          if (v.user_id == item.user_id) {
            this.$set(v, 'select', false)
            this.$set(v, 'lock', false)
          }
        })
      },
      // 获取群成员列表
      getGroupUserList(group_id, type) {
        // type==1,复制而不是push
        this.currentgGoupFriendID = []
        const data = {
          group_id,
          per_page: 30,
          page: this.page
        }
        this.$http.getGroupUserList(data).then(res => {
          if (res.status == 200) {
            this.getGroupUserListTotal = res.data.total
            if (type == 1) {
              this.groupFriendList = res.data.data
            } else {
              this.groupFriendList.push(...res.data.data)
            }

            this.judgingPeopleNum(res.data.data)
            this.currentgGoupFriendID = res.data.data.map(v => v.user_id)
          }
        })
      },

      nextFriendPage() {
        this.page += 1
        this.getGroupUserList(this.currentUserOrGroupId)
      },

      // 判断当前群聊人数及显示是否展开更多
      judgingPeopleNum(data) {
        if (this.judgeUserGroupleader && data.length > 8) {
          this.friendNum = 8
          this.seeMoreShow = true
        } else if (data.length > 9) {
          this.friendNum = 9
          this.seeMoreShow = true
        } else {
          this.friendNum = data.length
        }
      },
      // 邀请和踢除参数过滤
      filterParams() {
        let group_user_id = []
        this.filterSelectFriend.forEach(v => {
          if (this.currentShowPopupType == 'add') {
            group_user_id.push(v.user_id)
          } else {
            group_user_id.push(v.user_id)
          }
        })
        return group_user_id
      },
      // 邀请好友进群
      async inviteFriendGroup() {
        this.invitePopLoading = true
        let group_user_id = this.filterParams()
        const data = {
          group_user_id,
          invite_id: this.dataChat.user_id,
          group_id: this.groupData.group_id
        }
        let res = await this.$http.inviteFriendGroup(data)
        if (res.status == 200) {
          this.invitePopLoading = false
          this.$bvModal.hide('addModifyPopup')
          await this.getGroupUserList(this.groupData.group_id, 1)
          await this.getChatRoomList()
          this.usersList.forEach(v => {
            if (v.type == 'group' && v.group_id == this.groupData.group_id) {
              this.groupData = v
            }
          })
        }
      },
      // 踢出群友
      async removeGroupUser() {
        this.invitePopLoading = true
        let remove_user_id = this.filterParams()
        const data = {
          group_id: this.groupData.group_id,
          remove_user_id,
          client_id: this.dataChat.client_id
        }
        let res = await this.$http.removeGroupUser(data)
        if (res.status == 200) {
          this.invitePopLoading = false
          this.$bvModal.hide('addModifyPopup')
          await this.getGroupUserList(this.groupData.group_id, 1)
          await this.getChatRoomList()
          this.usersList.forEach(v => {
            if (v.type == 'group' && v.group_id == this.groupData.group_id) {
              this.groupData = v
            }
          })
        }
      },
      // 修改当前群组名称
      updateGroupName() {
        const data = {
          user_id: this.dataChat.user_id,
          group_id: this.groupData.group_id,
          group_name: this.editGroupData.group_name
        }
        this.$http.updateGroupName(data).then(res => {
          if (res.status == 200) {
            this.groupData.group_name = this.editGroupData.group_name
            this.usersList.forEach((v, i) => {
              if (v.group_id == this.groupData.group_id) {
                this.usersList.splice(i, 1)
                this.usersList.unshift(v)
                v.group_name = this.editGroupData.group_name
              }
            })
            this.getUserGroup()
          }
        })
      },
      // 获取群列表
      getUserGroup() {
        const data = {
          client_id: this.dataChat.client_id,
          type: 'getUserGroup',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        this.$http.getUserGroup(data).then(res => {
          if (res.status == 200) {
            this.CHANGE_DATA_CHAT({
              type: 'groupList',
              groupList: res.data
            })
          }
        })
      },
      // 获取指定好友信息详情
      getFriendInformation(user_id) {
        const data = {
          user_id
        }
        this.$http.getFriendInformation(data).then(res => {
          if (res.status == 200) {
            this.friendSelfInfo = res.data
          }
        })
      },
      loadingIcon(item) {
        return this.timestampList.includes(item.send_status)
      },
      // 一对一发送消息（好友聊天）
      sendChat(type) {
        if (!this.content) return false
        let timestamp = new Date()
        this.timestampList.push(timestamp)
        const params = {
          push_user_id: this.friendData.user_id,
          msg_type: type,
          content: this.content
        }
        let data = {
          content: this.content, // 发送的内容
          msg_type: type, // 发送类型 => 暂定 'text'
          send_user_id: this.dataChat.user_id,
          send_time: new Date().Format('yyyy-MM-dd hh:mm:ss'),
          send_status: timestamp
        }
        this.usersList.forEach((v, i) => {
          let item = v
          if (v.user_id == this.friendData.user_id) {
            this.$set(v, 'last_msg', this.content)
            this.usersList.splice(i, 1)
            this.usersList.unshift(item)
          }
        })
        this.messageContent[`friend-${this.friendData.user_id}`].push(data)
        // 发送成功后将输入框清空
        this.content = ''
        this.$nextTick().then(() => {
          this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight
        })
        this.$http.sendChat(params).then(res => {
          this.timestampList.splice(this.timestampList.indexOf(timestamp), 1)
        })
      },
      // 发送群消息
      sendGroup(type) {
        if (type == 5) {
          this.timestamp = new Date()
          this.timestampList.push(this.timestamp)
          const data = {
            type: 'sendGroup',
            client_id: this.dataChat.client_id,
            msg_type: type,
            group_id: this.groupData.group_id,
            content: this.sticker,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
          }
          this.$http.sendGroup(data).then(res => {
            if (res.status == 200) {
              this.usersList.forEach((v, i) => {
                if (v.group_id == this.groupData.group_id) {
                  this.$set(v, 'last_msg', this.content)
                }
              })
              this.content = ''
            }
          })
        } else {
          if (this.content) {
            this.timestamp = new Date()
            this.timestampList.push(this.timestamp)
            const data = {
              type: 'sendGroup',
              client_id: this.dataChat.client_id,
              msg_type: type,
              group_id: this.groupData.group_id,
              content: this.content,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            this.$http.sendGroup(data).then(res => {
              if (res.status == 200) {
                this.usersList.forEach((v, i) => {
                  if (v.group_id == this.groupData.group_id) {
                    this.$set(v, 'last_msg', this.content)
                  }
                })
                this.content = ''
              }
            })
          } else {
            this.$message("Can't send empty message")
          }
        }
      },
      // 点击发送并判断要发送消息的类型
      handleClickSend(type) {
        if (type == 5) {
          if (this.currentChatUser.type == 'friend') {
            this.content = this.sticker
            this.sendChat(type)
          } else {
            this.sendGroup(type)
          }
        } else {
          if (this.currentChatUser.type == 'friend') {
            this.sendChat(1)
          } else {
            this.sendGroup(1)
          }
        }
      },
      async handleClickItemFriend(item) {
        await this.getFriendInfo(item.send_user_id)
        this.$set(this.friendDetail, 'user_id', item.send_user_id)
      },
      // 获取好友信息
      async getFriendInfo(friend_id) {
        const data = {
          friend_id
        }
        let res = await this.$http.getFriendInfo(data)
        if (res.status == 200) {
          this.friendDetail = res.data
        }
      },
      /// ///////////////////// 子组件事件  ///////////////////
      // 监听子组件  == > 传递过来的数据
      async getClickItemInfo(data) {
        this.groupFriendList = []
        this.page = 1
        this.groupType = data.group_type
        this.currentUserOrGroupId = data.user_id || data.group_id
        this.scrollHeight = 0
        this.timeList = []
        // 切换 , 清空发送输入框内容
        this.content = ''
        // 切换后重置页码
        this.currentPageNum = 1
        // 清空 聊天列表
        this.messageContent = {}
        if (data.type == 'friend') {
          this.currentChatUser = {
            type: data.type,
            id: data.user_id
          }
          this.friendData = data
          this.$set(data, 'subscript', 0)
          // 点击获取当前好友聊天记录
          await this.getOfflineFriendsMessage(data.user_id)
          this.getFriendInfo(data.user_id)
        } else {
          this.currentChatUser = {
            type: data.type,
            id: data.group_id
          }
          this.$set(data, 'subscript', 0)
          // 获取群聊天记录
          await this.getOfflineGroupMessage(data.group_id)
          this.groupData = JSON.parse(JSON.stringify(data))
          this.editGroupData.group_name = this.groupData.group_name
          this.getGroupUserList(data.group_id)
        }

        this.$nextTick(() => {
          this.$refs.myGroupToggleContent.dismiss()
          this.$refs.myFriendToggleContent.dismiss()
          this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight
        })
      },

      selectEmoji(item) {
        this.content += item
      },
      seleSticker(url) {
        this.sticker = url
        this.handleClickSend(5)
      },

      closeAllPopup() {
        document.querySelector('#chat-user-detail-popup') ?.classList.remove('show')
        document.querySelector('#chat-user-info') ?.classList.remove('show')
        document.querySelector('#chat-group-detail-popup') ?.classList.remove('show')
      }
    },
    destroyed() {

      let light = document.getElementsByClassName('light')[0]
      let gradient = document.getElementsByClassName('gradient')[0]
      light.style.overflow = ''
      gradient.style.overflow = ''
    }
  }

</script>
<style scope lang="scss">
@media (min-width: 600px){
  .chat-message-share {
    width: 50%;
    margin: 0 1.33rem 1.33rem 0.3rem;
  }
  .widthStyle {
    width: 120px;
  }
  .borderStyle {
    height: 80px;
  }
  .avatar-60{
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 600px){
  .avatar-60{
    width: 50px !important;
    height: 50px !important;
  }
  .chat-message-share {
    width: 90%;
    margin: 0 1rem 1rem 0.3rem;
  }
  .borderStyle {
    height: 60px;
  }
  .chatName{
    width: 105px;
  }
  .widthStyle{
    width: 50px;
  }
}
  @media screen and (min-width: 992px) {
    #addModifyPopup {
      .modal-dialog {
        max-width: 620px !important;
      }
    }
    .col-lg-9 {
      flex: 65%;
    }
  }
  @media screen and (min-width: 1366px) {
    .col-lg-9 {
      flex: 75%;
    }
  }
  .emoji_btn {
    margin-top: 10px;
    padding: 12px 15px 0 6px;
    border-top: 1px solid #f1f1f1;

    .grey_emoji {
      color: #666;
    }
  }

  .valInfo {
    text-align: right;
  }

  .overHidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .el-input--suffix .el-input__inner {
    /* border-radius: 10px; */
    height: 45px;
  }

  .chat-footer {
    box-shadow: 0px 0px 20px 0px rgba(13, 15, 47, 0.1);
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .chat-content {
    overflow-anchor: auto;
    margin-bottom: 85px;
    height: calc(88vh - 160px) !important;
  }

  .chat-data-right {
    height: 88vh;
  }

  .chat-message>p {
    text-align: left;
    /* word-break: break-all; */
  }

  .borderStyle {
    display: flex;
    overflow: hidden;
    align-items: center;
    padding-left: 10px;
  }

  .tabActive {
    background: var(--iq-light-primary);
    padding-left: 10px;
  }

  .remove-toggle::after {
    content: unset;
  }

  .friendSelfAvatar {
    width: 150px;
    height: 150px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .tabNavItems {
    .nav-link {
      border: none !important;
    }
  }

  #chat-group-detail-popup {
    .avatarBox {
      width: 50px;
      height: 50px;
      overflow: hidden;
      margin-right: 5px;
      margin-bottom: 10px;
      border-radius: 5px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }

    .addFrendBox {
      background: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 30px;
        color: #dddddd;
        border-radius: 2px;
      }
    }

    .operation {
      font-size: 16px;
      color: #999999;
      cursor: pointer;
    }

    .groupName {
      display: flex;
      justify-content: center;
      margin: 50px 0;

      .groupNameBox {
        display: flex;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        position: relative;

        .form-control {
          border: 0;
          text-align: center;
        }

        .edit {
          display: inline;
          cursor: pointer;
          position: absolute;
          top: 50%;
          right: -20px;
          transform: translateY(-50%);
          z-index: -1;
        }
      }
    }

    .info {
      .size {
        font-weight: 500;
        color: #666666;
      }

      .num {
        font-weight: 300;
        color: #666666;
      }
    }

    .delete {
      color: #ef5959;
      cursor: pointer;
    }
  }

  .chat-user-info {
    margin-right: 0 !important;
  }

  .chatuser-detail {
    text-align: left !important;
  }

  #addModifyPopup {
    .modal-dialog {
      .modal-content {
        min-height: 520px !important;

        .modal-body {
          padding: 0;

          .contentLeft {
            padding: 1rem;
            padding-left: 22px;
            height: 460px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0 !important;
            }
          }

          .contentRight {
            padding: 1rem;
            padding-right: 30px;
            height: 460px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0 !important;
            }

            .title {
              font-size: 14px;
              color: #666666;
              margin-bottom: 30px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .promptMessage {
    background: #fff;
    font-size: 12px;
    padding: 0 20px;
    border-radius: 10px;
    padding: 5px;
    color: #a9a9a9;
    margin-bottom: 1.8rem;
  }

  // 分享样式集
  .chat-message-share {
    text-align: left;
    float: right;
    clear: both;
    background: var(--iq-white);
    border-radius: 15px;
    padding: 10px;
    border: none;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(44, 101, 144, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(44, 101, 144, 0.1);
  }

  .chat-left {
    .chat-message-share {
      text-align: left;
      // width: 50%;
      float: left;
      clear: both;
      background: var(--iq-white);
      border-radius: 15px;
      padding: 10px;
      margin: 0 0.3rem 1.8rem 1.3rem;
      border: none;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(44, 101, 144, 0.1);
      box-shadow: 0px 0px 20px 0px rgba(44, 101, 144, 0.1);
    }
  }

  .chat-message {
    position: relative;

    .el-icon-loading {
      position: absolute;
      left: -20px;
      top: 40%;
    }
  }

</style>
