<template>
  <section class="SharePostCard">
    <!-- 发帖     => 分享 -->
    <div class="chat-detail">
      <div class="chat-message-share  cursor-pointer" @click="handleClickJudgment">
        <div class="d-flex align-items-center mb-2">
          <div class="headPortrait">
            <img :src="params.avatar" alt="" />
          </div>
          <div class="name">
            {{ params.nick_name }}
          </div>
        </div>
        <div class="detailsDesc" v-html="postContentFilter(params.content)"></div>
        <ul class="m-0 p-0 list" v-if="params.media.length">
          <li class="detailImg" v-for="(v, i) in params.media" :key="i" v-show="v.type === 'photo'">
            <img :src="v.url" alt="" />
          </li>
        </ul>
        <hr class="mb-2" />
        <p class="mb-0 cardDesc">{{$t('Chat.d91@share_a_post_to_you')}}</p>
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    name: "",
    props: {
      params: {
        type: Object,
      },
    },
    components: {},
    data() {
      return {};
    },
    watch: {},
    methods: {
      // 帖子内容过滤
      postContentFilter(val) {
        let exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        val = val.replace(
          exp,
          "<a href='$1' target='_blank'><i class='ri-link-m'></i>Link</a>"
        );
        let url = window.location.href.split('#')[0]
        let reg=/(^|\s+)#([^!@#$%^&*()=+./,:'"?<>(^|\s+)]+)/g
        val = val.replace(reg, `<a href='${url}#/search-result?keyword=$2'>$1#$2</a>`)
        val = val.replace(/(\r\n|\n|\r)/gm, "<br />")
        return val
      },
      handleClickJudgment() {
        const data = {
          share_type: 1,
          share_id: this.params.id,
        };
        this.$http.sharedContentExist(data).then((res) => {
          if (res.status == 200) {
            if (res.data) {
              this.$router.push(`/newsfeeds/${this.params.info_url}`);
            } else {
              //this.$message.warning("This post has been deleted!");
              this.$message.warning($t('Chat.952@this_post_has_been_d'));
            }
          }
        });
      },
    },
    created() { },
  };
</script>
<style src='' lang='scss' scoped>
  .SharePostCard {
    .headPortrait {
      width: 20px;
      height: 20px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      font-size: 12px;
      font-family: "poppins", "montserra";
      color: #666666;
      margin-left: 12px;
    }

    .detailsDesc {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-family: "poppins", "montserra";
      color: #333333;
      line-height: 24px;
    }

    .list {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;

      .detailImg {
        width: 60px;
        height: 60px;
        border-radius: 5px;
        margin-right: 10px;
        margin-bottom: 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .cardDesc {
      letter-spacing: 0px;
      font-size: 12px;
      font-family: "montserra";
    }
  }
</style>