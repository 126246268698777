<template>
  <div @click="emoji">
    <!-- <el-popover placement="bottom" width="400" style="height:400px" trigger="click"> -->
      <div class="emotionList">
        <a href="javascript:void(0);" @click="getEmo(item)" v-for="(item, index) in faceList" :key="index"
          class="emotionItem">{{ item }}</a>
      </div>
      <!-- <a slot="reference" href="javascript:void(0)"><i class="fa fa-smile-o pr-3" aria-hidden="true"></i></a> -->
    <!-- </el-popover> -->
    <!-- <el-popover placement="top-start" title="标题" width="200" trigger="hover">
      <div>
        <p>123</p>
        <p>123123</p>
        <p>123123123</p>
      </div>
      <a slot="reference" href="javascript:void(0)"
        ><i class="fa fa-smile-o pr-3" aria-hidden="true"></i
      ></a>
    </el-popover> -->
  </div>
</template>

<script>
  const appData = require("../emoji.json");
  export default {
    mounted() {
      for (let i in appData) {
        this.faceList.push(appData[i].char);
      }
    },
    data() {
      return {
        faceList: [],
      };
    },

    components: {},

    created() {
    },

    methods: {
      emoji() {},
      getEmo(item) {
        this.$emit('selectEmoji', item)
      },
    },
  };
</script>
<style scoped>
  .emotionList {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
  }

  .emotionItem {
    width: 30px;
    font-size: 20px;
    text-align: center;
  }
</style>