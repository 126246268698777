<template>
  <section class="ShareAwardCard">
    <!-- 卡片     => 分享 -->
    <div class="chat-detail">
      <div class="chat-message-share">
        <div class="d-flex">
          <div class="shareCard">
            <img src="" alt="" />
          </div>
          <div>
            <div class="mb-1 title">
              {{ item.content | fliterTitle }}
            </div>
            <div class="time">
              {{ item.content | fliterTime }}
            </div>
          </div>
        </div>
        <hr class="mb-2" />
        <p class="mb-0 cardDesc">{{$t('Chat.ecb@share_a_card_to_you')}}</p>
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    name: "",
    props: {
      item: {
        type: Object,
      },
    },
    components: {},
    data() {
      return {};
    },
    computed: {},
    watch: {},
    methods: {},
    created() {},
  };

</script>
<style src='' lang='scss' scoped>
  @media (min-width: 600px){
    .time {
      font-size: 12px;
    }
  }
  @media (max-width: 600px){
    .time {
      font-size: 8px;
    }
  }
  .ShareAwardCard {
    .shareCard {
      width: 60px;
      height: 60px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .title {
      font-family: "poppins", "montserra";
      color: #333333;
    }

    .time {
      font-family: "poppins", "montserra";
      color: #666666;
    }

    .cardDesc {
      letter-spacing: 0px;
      font-size: 12px;
      font-family: "montserra";
    }
  }

</style>
