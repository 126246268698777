<template>
  <section class="ShareAwardCard">
    <!-- 奖项     => 分享 -->
    <div class="chat-detail">
      <div class="chat-message-share cursor-pointer" @click="handleClickJudgment">
        <div class="d-flex">
          <div class="courseImg">
            <!-- <i class="ri-award-line"></i> -->
            <img :src="params.avatar" alt="" />
          </div>
          <div>
            <div class="mb-1 title" :title="params.title">
              {{ params.title }}
            </div>
            <!-- <div class="time">
              {{ params.award_selection_time }}
            </div> -->
          </div>
        </div>
        <hr class="mb-2" />
        <p class="mb-0 cardDesc">{{ $t("Chat.952@share_a_award_to_you") }}</p>
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    name: "",
    props: {
      params: {
        type: Object,
      },
    },
    components: {},
    data() {
      return {};
    },
    watch: {},
    methods: {
      handleClickJudgment() {
        const data = { share_type: 7, share_id: this.params.id }
        this.$http.sharedContentExist(data).then((res) => {
          if (res.status == 200) {
            if (res.data) {
              this.$router.push(`/award-detail?id=${this.params.id}`);
            } else {
              // this.$message.warning("The award has been deleted!");
              this.$message.warning(this.$t('Chat.3d4@the_award_has_been_d'));
            }
          }
        });
      },
    },
    created() { },
  };

</script>
<style src='' lang='scss' scoped>
  @media (min-width: 600px){
    .title{
      width: 210px;
    }
    .time {
      font-size: 12px;
    }
  }
  @media (max-width: 600px){
    .title{
      width: 70px;
    }
    .time {
      font-size: 8px;
    }
  }
  .ShareAwardCard {
    .courseImg {
      width: 60px;
      height: 60px;
      background: #f5fbff;
      border-radius: 5px;
      margin-right: 15px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
      }
    }


    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: "poppins", "montserra";
      color: #333333;
    }

    .time {
      font-family: "poppins", "montserra";
      color: #666666;
    }

    .cardDesc {
      letter-spacing: 0px;
      font-size: 12px;
      font-family: "montserra";
    }
  }
</style>